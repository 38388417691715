import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import emailjs from "@emailjs/browser";
import * as yup from "yup";
import { toast } from "react-toastify";

const schema = yup.object().shape({
  user_name: yup.string().required("This field is required"),
  user_email: yup
    .string()
    .email("Insert a valid e-mail format")
    .required("This field is required"),
  message: yup.string().required("This field is required"),
});

const ContactMeForm = (props) => {
  const { onCloseModal } = props;
  const form = React.useRef();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const retriveErrorsFormHandler = (obj, field) => {
    if (!obj[field]) return;
    return obj[field].message;
  };

  const onSendEmailHandler = () => {
    emailjs
      .sendForm(
        "service_z1ia6qg",
        "template_62x7vuh",
        form.current,
        "user_sGkbfYFXQkn7YgUoDFDMH"
      )
      .then(
        (result) => {
          onCloseModal();
          toast.success("Email Sent !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        },
        (error) => {
          onCloseModal();
          toast.error("Email Not Sent !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      );
  };

  return (
    <form
      ref={form}
      onSubmit={handleSubmit(onSendEmailHandler)}
      className="w-full"
    >
      <div className="flex flex-col space-y-5">
        <div className="flex flex-col space-y-1">
          <div className="w-full flex items-center justify-between">
            <label htmlFor="user_name" className="text-slate-50 text-sm">
              First Name
            </label>
            <span className="text-red-400 text-xs">
              {retriveErrorsFormHandler(errors, "user_name")}
            </span>
          </div>
          <input
            name="user_name"
            {...register("user_name")}
            className="py-2 px-3 bg-slate-900 border border-green-400 text-slate-50 outline-none rounded-sm"
          />
        </div>
        <div className="flex flex-col space-y-1">
          <div className="w-full flex items-center justify-between">
            <label htmlFor="user_email" className="text-slate-50 text-sm">
              Your Email
            </label>
            <span className="text-red-400 text-xs">
              {retriveErrorsFormHandler(errors, "user_email")}
            </span>
          </div>
          <input
            name="user_email"
            {...register("user_email")}
            className="py-2 px-3 bg-slate-900 border border-green-400 text-slate-50 outline-none rounded-sm"
          />
        </div>
        <div className="flex pb-3 flex-col space-y-1">
          <div className="w-full flex items-center justify-between">
            <label htmlFor="message" className="text-slate-50 text-sm">
              Description
            </label>
            <span className="text-red-400 text-xs">
              {retriveErrorsFormHandler(errors, "message")}
            </span>
          </div>
          <textarea
            row={5}
            name="message"
            {...register("message")}
            className="py-2 px-3 bg-slate-900 border border-green-400 text-slate-50 outline-none rounded-sm"
          />
        </div>

        <button className="w-full text-black font-semibold bg-green-400 py-2 rounded-sm">
          SEND
        </button>
      </div>

      <input type="submit" />
    </form>
  );
};

export default ContactMeForm;
