import React from "react";
import Lines from "../../Components/Lines";
import Navbar from "../../Components/Navbar";
import { motion } from "framer-motion";
import AnimatedText from "../../Components/AnimatedText";
import LoaderSection from "../../Components/LoaderSection";

import {
  AiFillGithub,
  AiFillLinkedin,
  AiFillTwitterCircle,
} from "react-icons/ai";

const Home = () => {
  return (
    <>
      <LoaderSection />
      <Navbar />
      <section className="h-screen w-full bg-black overflow-hidden">
        <div className="w-full h-full flex flex-col px-[2rem] space-y-3 items-start md:items-center  justify-center">
          <AnimatedText
            delay={1.9}
            text={"I'm Aldo Noschese , Web Developer"}
          />
          <motion.div
            className="text-slate-50 text-sm md:text-md"
            animate={{
              opacity: [0, 1],
              scale: [1.2, 1],
            }}
            transition={{
              delay: 0,
              duration: 1,
            }}
          >
            <h1 className="md:hidden flex overflow-hidden text-3xl md:text-4xl font-bold text-slate-50">
              I'm Aldo Noschese , Web Developer
            </h1>
            I work with <span className="font-bold text-green-400">#React</span>{" "}
            , <span className="font-bold text-green-400">#Node</span> and many
            other technologies
          </motion.div>
          <div className="flex w-full space-x-8 items-center justify-start md:justify-center text-slate-50 pt-3">
            <a
              href="https://www.linkedin.com/in/aldo-noschese-5b9101193/"
              target="_blank"
              rel="noreferrer"
            >
              <AiFillLinkedin className="w-6 h-6 hover:text-green-400 transition-colors duration-400 cursor-pointer" />
            </a>
            <a
              href="https://github.com/AldoNoschese96"
              target="_blank"
              rel="noreferrer"
            >
              <AiFillGithub className="w-6 h-6 hover:text-green-400 transition-colors duration-400 cursor-pointer" />
            </a>
            <a
              href="https://twitter.com/noschese_aldo"
              target="_blank"
              rel="noreferrer"
            >
              <AiFillTwitterCircle className="w-6 h-6 hover:text-green-400 transition-colors duration-400 cursor-pointer" />
            </a>
          </div>
        </div>
        <Lines />
      </section>
    </>
  );
};

export default Home;
