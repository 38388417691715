import React from "react";
import { motion } from "framer-motion";

const LoaderSection = (props) => {
  const { texts = ["Hello.", "Welcome", "to", "my", "world."], delay = 1.9 } =
    props;
  const [currentString, setCurrentString] = React.useState("");
  const [counter, setCounter] = React.useState(0);

  const strings = React.useMemo(() => texts, []);
  const delayDiv = React.useMemo(() => delay, []);

  React.useEffect(() => {
    let id;
    if (counter < strings.length) {
      id = setInterval(() => {
        setCurrentString(strings[counter]);
        setCounter((prev) => prev + 1);
      }, 300);
    }

    return () => id && clearInterval(id);
  }, [counter, strings]);
  return (
    <>
      <motion.div
        initial={{ translateX: 0 }}
        animate={{ translateX: -2000, opacity: [1, 0] }}
        transition={{
          duration: 0.3,
          delay: delayDiv,
        }}
        exit={{ opacity: 0 }}
        className="absolute top-0 left-0 w-full h-screen bg-black z-50"
      >
        <div className="w-full h-full flex justify-center items-center">
          <motion.h1
            className="z-50 text-slate-50 text-3xl md:text-8xl font-bold"
            animate={{
              scale: [1.3, 1],
              opacity: [0, 1],
            }}
            transition={{
              duration: 0.5,
              delay: 0,
            }}
          >
            {currentString}
          </motion.h1>
          {/* <motion.h1
            className="z-50 text-slate-50 text-8xl font-bold"
            animate={{
              scale: [1.3, 1],
              opacity: [0, 1],
              display: ["block", "none"],
            }}
            transition={{
              duration: 0.5,
              delay: 0.2,
            }}
          >
            Welcome
          </motion.h1> */}
        </div>
      </motion.div>
    </>
  );
};

export default LoaderSection;
