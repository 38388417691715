import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import AboutMe from "./Pages/AboutMe";
import CoomingSoon from "./Pages/ComingSoon";
import Home from "./Pages/Home";
import MySkills from "./Pages/MySkills";
import NotFound from "./Pages/404/Index";

const AnimatedRoutes = () => {
  const location = useLocation();
  return (
    <Routes location={location} key={location.pathname}>
      <Route path="/" exact element={<Home />} />
      <Route path="/my-skills" exact element={<MySkills />} />
      <Route path="/about-me" exact element={<AboutMe />} />
      <Route path="*" exact element={<NotFound />} />
    </Routes>
  );
};

export default AnimatedRoutes;
