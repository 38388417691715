import React from "react";

const NotFound = () => {
  return (
    <section className="h-screen w-full bg-black overflow-hidden">
      <div
        className={
          "grid place-content-center w-full h-full text-center gap-y-5"
        }
      >
        <h1
          className={
            "text-5xl sm:text-7xl md:text-9xl text-slate-50 font-extrabold"
          }
        >
          4<span className={"text-green-400"}>0</span>4
        </h1>
        <h2
          className={
            "text-sm sm:text-xl md:text-2xl text-slate-50 font-extrabold"
          }
        >
          This page does{" "}
          <span className={"text-green-400 tracking-widest"}>not</span> exist.
        </h2>
      </div>
    </section>
  );
};

export default NotFound;
