import React from "react";
import Navbar from "../../Components/Navbar";
import Section from "../../Components/Section";
import LoaderSection from "../../Components/LoaderSection";
import TagCloud from "TagCloud";
import useMediaQuery from "../../Hook/useMediaQuery";
import Lines from "../../Components/Lines";

const texts = [
  "React",
  "TailwindCSS",
  "JavaScript",
  "CSS3",
  "HTML",
  "Bootstrap",
  "Recoil",
  "Node",
  "MongoDB",
  "MySql",
  "AWS",
];

const AboutMe = () => {
  const isSM = useMediaQuery("(max-width: 640px)");
  const isMD = useMediaQuery("(max-width: 760px)");

  const handleRadius = () => {
    if (isSM && !isMD) return 150;
    if (isMD && !isSM) return 200;
    if (isMD && isSM) return 150;
    return 200;
  };

  React.useEffect(() => {
    TagCloud(".content", texts, {
      radius: handleRadius(),
      maxSpeed: "fast",
      initSpeed: "fast",
      direction: 135,
      keep: true,
    });
    document.querySelector(".content").style.color = "#4ade80";
    const elements = document.querySelectorAll(".tagcloud");
    const ln = elements.length;
    elements.forEach((el, i) => {
      if (ln > 1 && ln - i !== ln - 1) {
        el.remove();
      }
    });
    const tag = document.querySelector(".tagcloud");
    tag.style.width = "100%";
    tag.style.margin = "0px";
  }, [isSM, isMD]);

  return (
    <>
      <LoaderSection texts={["About Me."]} delay={1} />
      <Navbar />
      <Section>
        <div className="flex min-h-screen items-center justify-center flex-col md:flex-row overflow-hidden">
          <div className="w-12/12 pb-[5rem] pt-[5rem] md:pt-[0rem] md:pb-0 flex flex-col space-y-5  md:w-6/12">
            <h1 className="text-xl sm:text-xl md:text-6xl font-bold text-green-400">
              Me, Myself and I.
            </h1>
            <div className="flex flex-col w-full space-y-5">
              <p className="text-md sm:text-lg text-slate-50">
                I’m a Front-End Developer located in Italy. I have been
                passionate about computer science since childhood.
              </p>
              <p className="text-md sm:text-lg text-slate-50">
                Well-organized person, problem solver, with high attention to
                detail. Passionate about JavaScript, travel , and everything
                related to the world of technology
              </p>
              <p className="text-lg sm:text-md text-green-400">
                Let's make something special.
              </p>
            </div>
          </div>
          <div className="w-12/12  md:w-6/12">
            <div className="content text-green-400"></div>
          </div>
        </div>
        <Lines />
      </Section>
    </>
  );
};

export default AboutMe;
