import React from "react";
import Navbar from "../../Components/Navbar";
import Section from "../../Components/Section";
import { motion } from "framer-motion";
import LoaderSection from "../../Components/LoaderSection";
import Lines from "../../Components/Lines";
import { Link } from "react-router-dom";

const MySkills = () => {
  return (
    <>
      <LoaderSection texts={["My Skills."]} delay={1} />
      <Navbar />
      <Section>
        <div className="flex min-h-screen items-center justify-center  flex-col md:flex-row overflow-x-hidden py-10">
          <div className="w-12/12 pb-[5rem] pt-[5rem] md:pt-[0rem] md:pb-0 flex flex-col space-y-5 md:w-5/12">
            <h1 className="text-xl sm:text-xl md:text-6xl font-bold text-green-400">
              Skills & <br className="hidden md:block" /> Experience
            </h1>
            <div className="flex flex-col w-full space-y-5">
              <p className="text-md sm:text-lg text-slate-50">
                I’ve done remote work for agencies, consulted for startups, and
                collaborated with talented people to create web products for
                both business and consumer use.
              </p>
              <p className="text-md sm:text-lg text-slate-50">
                The main area of my expertise is front-end development, HTML,
                CSS, JS, building small and medium web apps, custom plugins,
                features, animations, and coding interactive layouts.
              </p>
              <p className="text-lg sm:text-md text-green-400">
                Visit my{" "}
                <a
                  href="https://www.linkedin.com/in/aldo-noschese-5b9101193/"
                  target="_blank"
                  rel="noreferrer"
                >
                  #LinkedIn{" "}
                </a>
                profile for more details.{" "}
                {/* <Link to="/contact-me">#contact me.</Link> */}
              </p>
            </div>
          </div>
          <div className="w-full h-full flex justify-center items-start md:items-center md:w-7/12 pl-0 md:pl-[8rem]">
            <div className="w-full flex flex-col space-y-10 md:space-y-20 ">
              <div className="flex flex-col w-full space-y-1">
                <span className="text-slate-50">Front-End Development</span>
                <div className="w-full h-2 bg-slate-900 ">
                  <div
                    animate={{
                      width: [0, 800],
                    }}
                    transition={{
                      duration: 0.5,
                      delay: 1,
                    }}
                    style={{
                      height: "100%",
                      width: "95%",
                      background: "#4ade80",
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-col w-full space-y-1">
                <span className="text-slate-50">React</span>
                <div className="w-full h-2 bg-slate-900">
                  <div
                    animate={{
                      width: [0, 700],
                    }}
                    transition={{
                      duration: 0.5,
                      delay: 1.5,
                    }}
                    style={{
                      // borderRadius: "0.5rem 0rem 1rem 0.5rem",
                      height: "100%",
                      width: "80%",
                      background: "#4ade80",
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-col w-full space-y-1">
                <span className="text-slate-50">Back-End Development</span>
                <div className="w-full h-2 bg-slate-900 ">
                  <div
                    animate={{
                      width: [0, 600],
                    }}
                    transition={{
                      duration: 0.5,
                      delay: 2,
                    }}
                    style={{
                      // borderRadius: "0.5rem 0rem 1rem 0.5rem",
                      height: "100%",
                      width: "70%",
                      background: "#4ade80",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Lines />
      </Section>
    </>
  );
};

export default MySkills;
