import React from "react";
import { motion } from "framer-motion";

const Lines = () => {
  return (
    <div className="hidden md:block">
      <div className="-z-10 absolute lg:z-10 top-0 left-80">
        <div className="flex space-x-3">
          <motion.div
            animate={{
              height: [0, 100],
            }}
            transition={{
              delay: 0,
              duration: 0.5,
            }}
            className="w-[5px] h-[100px] bg-slate-50 rounded-b-lg"
          />
          <motion.div
            animate={{
              height: [0, 100],
            }}
            transition={{
              delay: 0.3,
              duration: 0.5,
            }}
            className="w-[5px] h-[100px] bg-green-400 rounded-b-lg"
          />
          <motion.div
            animate={{
              height: [0, 100],
              duration: 0.5,
            }}
            transition={{
              delay: 0.6,
              duration: 0.5,
            }}
            className="w-[5px] h-[100px] bg-slate-50 rounded-b-lg"
          />
          <motion.div
            animate={{
              height: [0, 100],
            }}
            transition={{
              delay: 0.9,
              duration: 0.8,
            }}
            className="w-[5px] h-[100px] bg-slate-50 rounded-b-lg"
          />
        </div>
      </div>
      <div className="-z-10 absolute lg:z-10 bottom-0 right-80">
        <div className="flex space-x-3">
          <motion.div
            animate={{
              height: [0, 100],
            }}
            transition={{
              delay: 0,
              duration: 0.5,
            }}
            className="w-[5px] h-[100px] bg-slate-50 rounded-b-lg rotate-180"
          />
          <motion.div
            animate={{
              height: [0, 100],
            }}
            transition={{
              delay: 0.3,
              duration: 0.5,
            }}
            className="w-[5px] h-[100px] bg-green-400 rounded-b-lg rotate-180"
          />
          <motion.div
            animate={{
              height: [0, 100],
              duration: 0.5,
            }}
            transition={{
              delay: 0.6,
              duration: 0.5,
            }}
            className="w-[5px] h-[100px] bg-slate-50 rounded-b-lg rotate-180"
          />
          <motion.div
            animate={{
              height: [0, 100],
            }}
            transition={{
              delay: 0.9,
              duration: 0.8,
            }}
            className="w-[5px] h-[100px] bg-slate-50 rounded-b-lg rotate-180"
          />
        </div>
      </div>
    </div>
  );
};

export default Lines;
