import React from "react";

const Section = (props) => {
  const { children } = props;
  return (
    <section className="bg-black px-[2rem] sm:px-[6rem] md:px-[6rem] overflow-hidden">
      {children}
    </section>
  );
};

export default Section;
