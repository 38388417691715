import { MotionConfig, AnimatePresence } from "framer-motion";
import { BrowserRouter } from "react-router-dom";
import AnimatedRoutes from "./AnimatedRoutes";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <MotionConfig reducedMotion="user">
      <AnimatePresence exitBeforeEnter={true}>
        <BrowserRouter>
          <AnimatedRoutes />
        </BrowserRouter>
      </AnimatePresence>
      <ToastContainer />
    </MotionConfig>
  );
}

export default App;
