import React, { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { BiMenu } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";

import { Link } from "react-router-dom";
import Modal from "../Modal";
import ContactMeForm from "../../Forms/ContactMeForm";

const solutions = [
  {
    name: "About Me",
    description: "Speak directly to your customers in a more meaningful way.",
    href: "/about-me",
  },
  {
    name: "My Skills",
    description: "Your customers' data will be safe and secure.",
    href: "/my-skills",
  },
];

const Navbar = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  return (
    <>
      <Popover className="fixed w-full bg-transparent px-[2rem] sm:px-[6rem] md:px-[6rem]">
        <div className="w-full">
          <div className="flex justify-between items-center py-4  space-x-10">
            <div className="flex justify-start  lg:w-0 lg:flex-1">
              <div href="#">
                <span className="sr-only">Workflow</span>
                <Link to="/">
                  <span className="text-xl font-bold text-green-400">
                    {"</>"}
                  </span>
                </Link>
              </div>
            </div>
            <div className="-mr-2 -my-2 md:hidden">
              <Popover.Button className="bg-transparent rounded-md p-2 pr-0 inline-flex items-center justify-center hover:text-green-400 text-gray-400 outline-none focus:outline-none ">
                <span className="sr-only">Open menu</span>
                <BiMenu className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
            <Popover.Group
              as="nav"
              className="hidden md:flex items-center space-x-10"
            >
              <Link to="/about-me">
                <span className="text-sm font-medium self-center text-gray-400 cursor-pointer hover:text-green-400">
                  About me
                </span>
              </Link>
              <Link to="/my-skills">
                <span className="text-sm font-medium self-center  text-gray-400 cursor-pointer hover:text-green-400">
                  My Skills
                </span>
              </Link>
              {/* <Link to="/portfolio">
                <span className="text-sm font-medium self-center  text-gray-400 cursor-pointer hover:text-green-400">
                  Portfolio
                </span>
              </Link> */}
              {/* <Link to="/contact-me"> */}
              <button
                onClick={() => setIsModalOpen(true)}
                className="font-medium border text-slate-50 text-sm border-green-400 py-1 px-3 rounded-sm shadow-lg hover:text-black hover:bg-green-400 transition-all duration-300"
              >
                Contact Me
              </button>
              {/* </Link> */}
            </Popover.Group>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
          >
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-black border border-green-400 divide-y-2 ">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div className="outline-none ring-0">
                    <Link to="/">
                      <span className="text-xl font-bold text-green-400 outline-none ring-0">
                        {"</>"}
                      </span>
                    </Link>
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="rounded-md p-2 inline-flex items-center justify-center focus:outline-none outline-none">
                      <span className="sr-only">Close menu</span>
                      <AiOutlineClose
                        className="h-4 w-4 text-slate-50"
                        aria-hidden="true"
                      />
                    </Popover.Button>
                  </div>
                </div>
                <div className="mt-6">
                  <nav className="grid gap-y-8">
                    {solutions.map((item, i) => (
                      <Link to={item.href} key={i}>
                        <a
                          key={item.name}
                          href={item.href}
                          className="-m-3 py-3 flex items-center rounded-md"
                        >
                          <span className="ml-3 text-base font-medium text-slate-50">
                            {item.name}
                          </span>
                        </a>
                      </Link>
                    ))}
                    <span
                      onClick={() => setIsModalOpen(true)}
                      className="-m-3 py-3 flex items-center rounded-md"
                    >
                      <span className="ml-3 text-base font-medium text-slate-50">
                        Contact Me
                      </span>
                    </span>
                  </nav>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ContactMeForm onCloseModal={() => setIsModalOpen(false)} />
      </Modal>
    </>
  );
};

export default Navbar;
